import React from "react";
import "../../css/customizations.css";
import { showDottedBackground, getDottedBackgroundColor } from "../../yourCustomizations/styling";

const DottedBackground = () => {
    return (
        <div className="dotted-background" style={{
            visibility: showDottedBackground ? 'visible' : 'hidden',
            "--dotted-background-color": `${getDottedBackgroundColor()}35`
        }}>
        </div>
    );
};

export default DottedBackground;