/**
 * The content for the About page
    * This should be information about your brand and the products and services you offer.
 * @type { object }
 **/
export const about = {
    topSection: {
        heading: "Like this Portfolio Template? Make it yours now.",
        subtext: "Seriously, it's that easy. Low Code. Brand Customizable. Modern & Responsive Design.",
        buttons: {
            primary: {
                text: "Get this Template",
                path: "/pricing"
            },
            secondary: {
                text: "See Live Examples",
                path: "/products"
            }
        },
        images: {
            first: "https://tonhiwqrovgizwfeagfl.supabase.co/storage/v1/object/public/VirtualVault/js-files-to-edit.png",
            second: "https://tonhiwqrovgizwfeagfl.supabase.co/storage/v1/object/public/VirtualVault/virtualvault-brand-js-file.png",
            third: "https://tonhiwqrovgizwfeagfl.supabase.co/storage/v1/object/public/VirtualVault/made-with-react.png?t=2024-11-07T07%3A09%3A18.668Z"
        }
    },
    features: [
        {
            title: "Lightweight. Frontend Only.",
            description: "All your website content lives in 4 light .js files. No backend, no database, no server",
        },
        {
            title: "100% Brand Customizable",
            description: "You can set a brand name, icon, and accent color to showcase your brand's vision",
        },
        {
            title: "Customize like you are 5",
            description: "Control the appearance of the Dotted Background, Spray Paint on page corners, cube wave at the bottom of the page",
        },
        {
            title: "Modern & Responsive Design",
            description: "Fully responsive and look great on any device. Go ahead, check this on a smaller device",
        },
        {
            title: "Light & Dark Mode",
            description: "Comes with light and dark mode options, toggle from the top right corner",
        },
        {
            title: "List Products/Services/Projects/Your Work",
            description: "Showcase your products, services, or projects with images and descriptions and categories"
        },
        {
            title: "Showcase similar products",
            description: "Show similar products on the product page based on the product's category"
        },
        {
            title: "Digital Business Card 101",
            description: "Show your contact information with icons for different platforms"
        },
        {
            title: "Low Code / Easy to Use",
            description: "Easy to use and customize, even for beginners."
        },
        {
            title: "More on the examples tab"
        },
        {
            title: "Not a developer? No problem",
            description: "Get in touch with me to customize this template for you"
        }
    ]
};