import React from "react";
import "../../css/customizations.css";
import { showSprayPaint, getSprayPaintColor } from "../../yourCustomizations/styling";

const SprayPaint = () => {
    return (
        <div
            className="spray-paint"
            style={{
                visibility: showSprayPaint ? 'visible' : 'hidden',
                '--brand-accent': `${getSprayPaintColor()}25`
            }}
        ></div>
    );
};

const SpacedSprayPaint = () => {
    return (
        <div
            className="spaced-spray-paint"
            style={{
                visibility: showSprayPaint ? 'visible' : 'hidden',
                '--brand-accent': `${getSprayPaintColor()}25`
            }}
        ></div>
    );
};

export default SprayPaint;
export { SpacedSprayPaint };