const getWaitlistCount = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/prerelease/waitlist/count`);
        const result = await response.json();
        return result.count;
    } catch (error) {
        return 'Error';
    }
}

const joinWaitlist = async (email) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/prerelease/waitlist/join`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
        });

        const result = await response.json();
        if (response.ok) {
            return { message: result.message };
        } else {
            return { error: result.error };
        }
    } catch (error) {
        return { error: 'An error occurred while joining the waitlist' };
    }
};

export { getWaitlistCount, joinWaitlist };