import React, { useState, useEffect } from 'react';
import "../css/pricingView.css";
import { Info } from 'lucide-react';
import { Link } from 'react-router-dom';
import { getWaitlistCount, joinWaitlist } from '../api/waitlist';

const Pricing = () => {
    const [email, setEmail] = useState('');
    const [joinWaitlistResponse, setJoinWaitlistResponse] = useState('');
    const [waitlistCount, setWaitlistCount] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const fetchWaitlistCount = async () => {
            const count = await getWaitlistCount();
            setWaitlistCount(count);
        };

        fetchWaitlistCount();
    }, []);

    const handleEmailChange = (event) => {
        setJoinWaitlistResponse('');
        setEmail(event.target.value);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return emailRegex.test(email);
    };

    const handleJoinWaitlist = async () => {
        if (!email) {
            setJoinWaitlistResponse('Email is required');
            return;
        }

        if (!validateEmail(email)) {
            setJoinWaitlistResponse('Invalid email address');
            return;
        }

        setIsSubmitting(true);
        const result = await joinWaitlist(email);
        setIsSubmitting(false);

        if (result.message) {
            setJoinWaitlistResponse(result.message);
        } else {
            setJoinWaitlistResponse(result.error);
        }

        setEmail('');
    };

    return (
        <div className="pricing-page-container">
            <div className="disclaimer">
                <Info size={18} strokeWidth={2} />
                <p>This page is not a part of the template</p>
            </div>
            <div className='pricing-box-container'>
                <div className="pricing-box">
                    <div className='pricing-box-text'>
                        <h2>Join Waitlist</h2>
                        <h3>Get notified when the template is available</h3>
                        {waitlistCount > 5 &&
                            <h3>{waitlistCount} people have already joined</h3>
                        }
                    </div>
                    <div className='actions-div'>
                        <input
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            placeholder="Email"
                            className="email-input"
                            disabled={isSubmitting}
                        />
                        <button className="primary-button join-button" onClick={handleJoinWaitlist} disabled={isSubmitting}>
                            {isSubmitting ? <span className="loading-circle"></span> : 'Join'}
                        </button>
                        <div className="joinWaitlistResponse-placeholder">
                            {joinWaitlistResponse && <p>{joinWaitlistResponse}</p>}
                        </div>
                    </div>
                </div>
                <div className="pricing-box">
                    <div className='pricing-box-text'>
                        <h2>Not a Developer?</h2>
                        <h3>Get this website tailored to your needs</h3>
                    </div>
                    <div className='actions-div'>
                        <Link to="/contact" className="primary-button join-button">Let's Talk</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pricing;